import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ngx-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnChanges {
  @Input() show_play_button: boolean = true;
  @Input() show_pause_button: boolean = false;
  @Input() show_stop_button: boolean = false;
  @Input() audioFile: string;
  @Input() updateFile: boolean;
  @Output() manageAudioContentEvent = new EventEmitter<string>();
  @Output() updatePageAudioFileEvent = new EventEmitter<Event>();

  audioPlayer: HTMLAudioElement;

  ngOnChanges(changes: SimpleChanges) {
    this.audioPlayer = new Audio();
    this.audioPlayer.src = this.randomizeFiles(this.audioFile);
    this.audioPlayer.load();
  }

  getRandomInt(min = 1, max = 99999999) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  randomizeFiles(file: string) {
    return file ? `${file}?dummy=${this.getRandomInt()}` : '';
  }

  manageAudioContent(action: string): void {
    if (action === 'pause') {
      this.audioPlayer.pause();
      this.show_play_button = true;
      this.show_pause_button = false;
      this.show_stop_button = true;
    } else if (action === 'stop') {
      this.audioPlayer.load();
      this.show_play_button = true;
      this.show_pause_button = false;
      this.show_stop_button = false;
    } else if (action === 'play') {
      this.audioPlayer.play();
      this.show_play_button = false;
      this.show_pause_button = true;
      this.show_stop_button = true;
    } else if (action === 'delete') {
      this.manageAudioContentEvent.emit('delete');
      this.show_play_button = true;
      this.show_pause_button = false;
      this.show_stop_button = false;
    }
  }

  updatePageAudioFile(event: Event): void {
    this.updatePageAudioFileEvent.emit(event);
  }
}
