import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../@core/services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.includes('login')) {
      const email = req.body && req.body.username ? req.body.username.trim() : '';
      const password = req.body && req.body.password ? req.body.password.trim() : '';

      const newBody = { email, password };
      const newReq = req.clone({ body: newBody });

      return next.handle(newReq).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (req.url.includes('login')) {
              this.authenticationService.login(event.body);
            }
          }
          return event;
        }),
      );
    }
    return next.handle(req);
  }
}
