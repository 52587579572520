import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbCardModule,
  NbButtonModule,
  NbAlertModule,
  NbCheckboxModule,
  NbSelectModule,
  NbInputModule,
} from '@nebular/theme';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { DualDragDropComponent } from './dual-drag-drop/dual-drag-drop.component';
import { SingleDragDropComponent } from './single-drag-drop/single-drag-drop.component';
import { LanguageCheckboxesComponent } from './language-checkboxes/language-checkboxes.component';
import { ItemSelectComponent } from './item-select';
import { VersionGestorComponent } from './version-gestor/version-gestor.component';

@NgModule({
  declarations: [
    AudioPlayerComponent,
    DualDragDropComponent,
    SingleDragDropComponent,
    LanguageCheckboxesComponent,
    ItemSelectComponent,
    VersionGestorComponent,
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbButtonModule,
    NbInputModule,
    NbAlertModule,
    NbCheckboxModule,
    NbSelectModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    AudioPlayerComponent,
    DualDragDropComponent,
    SingleDragDropComponent,
    LanguageCheckboxesComponent,
    ItemSelectComponent,
    VersionGestorComponent,
  ],
})
export class ComponentsModule { }
