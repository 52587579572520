import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ngx-version-gestor',
  templateUrl: './version-gestor.component.html',
  styleUrls: ['./version-gestor.component.scss'],
})
export class VersionGestorComponent {
  @Input() form: FormGroup;
  @Input() selectorName: string;
}
