import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ngx-item-select',
  templateUrl: './item-select.component.html',
  styleUrls: ['./item-select.component.scss'],
})
export class ItemSelectComponent {
  @Input() form: FormGroup;
  @Input() items: { name: string, code: string }[];
  @Input() selectorName: string;
  @Output() selectedChange = new EventEmitter<string>();

  onChangeType(value: string) {
    this.selectedChange.emit(value);
  }
}
