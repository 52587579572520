export const environment = {
   production: true,
   apiUrl: 'https://api.storybook-app.com',
   challengeUrl: 'https://api-challenges.storybook-app.com',
   virtualPetUrl: 'https://virtual-pet.storybook-app.com',
   translationProject: 'api-prod',
   challengesProject: 'challenges-prod',
   virtualPetProject: 'undefined',
   virtualPetApiKey: '1e787be2-6cf1-4b6b-bc78-6f8796481d8e',
   tokenAuthentication: true,
};
