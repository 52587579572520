import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from './@core/services/jwt_service';
@Injectable()
export class JwtGuard implements CanActivate {

    constructor(
        private _userService: UserService,
        private router: Router,
        ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this._userService.isAuthenticated()) {
            return true;
        } else {
            this.router.navigate(['auth/jwt']);
            return false;
        }
    }
}
