import { Component, Input } from '@angular/core';
import { Language } from '../../@core/data/content/language';
import { StoryDetail } from '../../@core/data/content/story-detail';

@Component({
  selector: 'ngx-language-checkboxes',
  templateUrl: './language-checkboxes.component.html',
  styleUrls: ['./language-checkboxes.component.scss'],
})
export class LanguageCheckboxesComponent {
  @Input() languages: Language[];
  @Input() storyDetailsByLang: { [key: string]: StoryDetail };
  @Input() detailField: string = 'is_published';
  @Input() accessBy: string = 'code';

  getDetail(lang: Language): any {
    return this.storyDetailsByLang[lang[this.accessBy]][this.detailField];
  }
}
