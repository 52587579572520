import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSetting } from '../app-settings';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginComponent } from '../../jwt/jwt.component';
import { User } from '../data/users';
import { CredentialsService } from './credentials.service';

@Injectable()
export class UserService {
  private API_ENDPOINT = AppSetting.API_ENDPOINT;
  private TOKEN_AUTHENTICATION = AppSetting.TOKEN_AUTHENTICATION;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private httpOptions: any;
  public access_token: string;
  public refresh_token: string;
  public valid_token: boolean = true;
  public token_expires: Date;
  public user_id: string;
  public errors: any = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private _loginComponent: LoginComponent,
    private tokenService: CredentialsService) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const storedUser = JSON.parse(localStorage.getItem('userLogin'));
    const defaultCompany = {
      id: null,
      name: '',
      logo: '',
    };
    const defaultUser: any = {
      id: 0,
      email: '',
      first_name: '',
      last_name: '',
      company: defaultCompany,
    };
    this.currentUserSubject = new BehaviorSubject<User>(storedUser || defaultUser);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public isAuthenticated() {
    return document.cookie.includes('token' + '=');
  }

  public readCookie(name): string {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        return decodeURIComponent(c.substring(nameEQ.length, c.length));
      }
    }
    return null;
  }

  public get currentUserValue() {
    return JSON.parse(localStorage.getItem('userLogin'));
  }

  async refreshToken() {
    const refreshToken = this.tokenService.getRefreshToken();
    this.http
      .post(
        `${this.API_ENDPOINT}/api/token/refresh/`,
        JSON.stringify({ refresh: refreshToken }),
        this.httpOptions,
      )
      .subscribe(
        (data) => {
          this.tokenService.setRefreshToken(data['refresh_token']);
          this.setAccessToken(data['access_token'], data['refresh_token']);
        },
        (err) => {
          this.errors = err['error'];
        },
      );
  }

  async validateTokenWithMaxAttempts(maxAttempts: number = 2): Promise<boolean> {
    let tokenAttempts = 0;
    while (tokenAttempts < maxAttempts) {
      const token = this.readCookie('token');
      const isValidToken = await this.validToken(token);
      if (isValidToken) {
        return true;
      } else {
        this.refreshToken();
        tokenAttempts++;
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
    return false;
  }

  private setAccessToken(token: string, refreshToken: string) {
    const expiresDateRefresh = this._loginComponent.expiresDate(refreshToken);
    document.cookie = `token=${token}; expires=${expiresDateRefresh.toUTCString()}; path=/`;
  }

  public header() {
    if (this.valid_token) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.readCookie('token'),
        }),
      };
    }
  }

  async validToken(token: any): Promise<boolean> {
    if (!this.TOKEN_AUTHENTICATION) {
      return true;
    }
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .post(
          `${this.API_ENDPOINT}/api/token/verify/`,
          JSON.stringify({ token: token }),
          this.httpOptions,
        )
        .subscribe(
          (data) => {
            this.valid_token = true;
            resolve(true);
          },
          (err) => {
            this.valid_token = false;
            resolve(false);
          },
        );
    });
  }

  public logout() {
    this.access_token = null;
    this.token_expires = null;
    this.refresh_token = null;
    this.user_id = null;
    localStorage.removeItem('userLogin');
    this.deleteCookies('token');
    this.router.navigate(['auth/jwt']);
  }

  private deleteCookies(cookieName: string) {
    const expiresDate = new Date('Thu, 01 Jan 1970 00:00:00 UTC').toUTCString();
    document.cookie = `${cookieName}=; expires=${expiresDate}; path=/; secure; samesite=lax`;
  }
}
