import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'ngx-dual-drag-drop',
  templateUrl: './dual-drag-drop.component.html',
  styleUrls: ['./dual-drag-drop.component.scss'],
})
export class DualDragDropComponent implements OnChanges {
  @Input() data: any[];
  @Input() activeItems: any[];
  @Input() displayField: string;
  @Input() returnProperty: string;
  @Input() tableTitle: string;
  @Output() itemsDropped = new EventEmitter<{ event: CdkDragDrop<any[]>, activeData: any[], availableData: any[] }>();
  @Output() activeDataProperty = new EventEmitter<number[]>();

  activeData: any[] = [];
  availableData: any[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.activeItems) {
      this.filterData();
      this.emitActiveDataProperty();
    }
  }

  filterData() {
    if (this.data && this.activeItems) {
      this.activeData = this.data.filter((item: any) => this.activeItems.includes(item[this.returnProperty]));
      this.availableData = this.data.filter((item: any) => !this.activeItems.includes(item[this.returnProperty]));
    } else {
      this.activeData = [];
      this.availableData = this.data || [];
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.itemsDropped.emit({ event, activeData: this.activeData, availableData: this.availableData });
    this.emitActiveDataProperty();
  }

  emitActiveDataProperty() {
    const propertyValues = this.activeData.map((item: any) => item[this.returnProperty]);
    this.activeDataProperty.emit(propertyValues);
  }
}
