import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../data/users';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('userLogin')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(user: any) {
        localStorage.setItem('userLogin', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }

    logout() {
        localStorage.removeItem('userLogin');
        this.deleteCookies('token');
        this.currentUserSubject.next(null);
    }

    private deleteCookies(cookieName: string) {
        const expiresDate = new Date('Thu, 01 Jan 1970 00:00:00 UTC').toUTCString();
        document.cookie = `${cookieName}=; expires=${expiresDate}; path=/; secure; samesite=lax`;
    }
}
