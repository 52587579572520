import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'ngx-single-drag-drop',
  templateUrl: './single-drag-drop.component.html',
  styleUrls: ['./single-drag-drop.component.scss'],
})
export class SingleDragDropComponent implements OnChanges {
  @Input() data: any[];
  @Input() displayField: string;
  @Output() itemAdded = new EventEmitter<void>();
  @Output() itemSelected = new EventEmitter<any>();
  @Output() itemDeleted = new EventEmitter<any>();
  @Output() itemsDropped = new EventEmitter<any[]>();

  ngOnChanges(changes: SimpleChanges) {}

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    this.itemsDropped.emit(this.data);
  }

  addItem() {
    this.itemAdded.emit();
  }

  selectItem(item: any) {
    this.itemSelected.emit(item);
  }

  deleteItem(event: MouseEvent, item: any) {
    event.stopPropagation();
    this.itemDeleted.emit(item);
  }
}
