import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSetting } from '../@core/app-settings';
import { Router } from '@angular/router';
import { Credentials } from '../@core/data/users';
import { CredentialsService } from '../@core/services/credentials.service';

@Component({
  selector: 'ngx-jwt',
  templateUrl: './jwt.component.html',
  styleUrls: ['./jwt.component.css'],
})
export class LoginComponent implements OnInit {

  public user: any;
  public error_list: string[] = [];
  public success_list: string[] = [];
  public access_list: string[] = [];
  private API_ENDPOINT = AppSetting.API_ENDPOINT;

  private httpOptions: any;
  public statusLogin: boolean = false;

  public access_token: string;
  public refresh_token: string;
  public expiresdate = new Date(2068, 1, 0o2, 11, 20);

  public errors: any = [];

  constructor(private http: HttpClient, private router: Router, private tokenService: CredentialsService) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
  }

  ngOnInit() {
    this.user = {
      username: '',
      password: '',
    };
    const isAuthenticated = this.checkUserAuthentication();
    if (isAuthenticated) {
      this.router.navigate(['pages/managment/parents']);
    }
  }

  public get currentCredentialsValue() {
    return JSON.parse(localStorage.getItem('userLogin'));
  }

  private checkUserAuthentication(): boolean {
    const token = this.getCookieValue('token');
    if (token) {
      try {
        return true;
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    }
    return false;
  }

  private getCookieValue(name: string): string | null {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  public login() {
    this.error_list = [];
    this.access_list = [];
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (this.user.username.length === 0) {
      this.error_list.push('The email field is required');
    }
    if (!emailRegex.test(this.user.username) && this.user.username.length > 0) {
      this.error_list.push('Oh no! Incorrect email, please verify that your email is valid.');
    }
    if (this.user.password.length === 0) {
      this.error_list.push('The password field is required');
    }
    if (this.error_list.length === 0) {
      this.statusLogin = true;
      this.http.post(`${this.API_ENDPOINT}/api/token/`, JSON.stringify(this.user), this.httpOptions).subscribe(
        data => {
          this.access_token = data['access'];
          this.refresh_token = data['refresh'];
          this.tokenService.setRefreshToken(this.refresh_token);
          this.loginAuthenticated(this.user, data);
        },
        err => {
          this.error_list.push('Wrong Credentials');
          this.statusLogin = false;
          this.errors = err['error'];
        },
      );
    }
  }

  public expiresDate(token: string) {
    const parts = token.split('.');
    const encodedPayload = parts[1];
    const payload = atob(encodedPayload);
    const payloadObject = JSON.parse(payload);
    const expirationTimeInSeconds = payloadObject.exp;
    return new Date(expirationTimeInSeconds * 1000);
  }

  public loginAuthenticated(user, data) {
    this.http.post(`${this.API_ENDPOINT}/v7/auth/o/login/web/email/`, user).subscribe(
      (profileInfo) => {
        this.success_list.push('Correct Credentials');
        this.success_list.push('Login...');
        const expiresDate = this.expiresDate(this.refresh_token);
        document.cookie = `token=${encodeURIComponent(this.access_token)}; expires=${expiresDate}; path=/`;
        localStorage.setItem('userLogin', JSON.stringify(profileInfo));
        this.router.navigate(['pages/managment/parents']);
        this.error_list = [];
      },
      err => {
        this.errors = err['error'];
        this.access_list.push('You do not have administrator permissions');
        this.statusLogin = false;
      },
    );
  }
}
