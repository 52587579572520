import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private refresh_token: string;

  setRefreshToken(refresh_token: string) {
    this.refresh_token = refresh_token;
  }

  getRefreshToken(): string {
    return this.refresh_token;
  }
}
